<template>
  <PageWrapper :tabs="pageTabs" :tabActive.sync="activeName">
    <div id="transactionHistory">
      <div class="export_form_box">
        <el-form ref="form" label-position="top">
          <el-form-item :label="$t('common.field.startTime')">
            <el-date-picker
              v-model="startDate"
              type="date"
              :clearable="false"
              :editable="false"
              :picker-options="dateOption"
              @change="changeStartDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('common.field.endTime')">
            <el-date-picker
              v-model="endDate"
              type="date"
              :clearable="false"
              :editable="false"
              :picker-options="dateOption"
              @change="changeEndDate"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-button class="btn_form_item" type="primary" @click="onExportClick">
          {{ $t('common.button.export') }}
        </el-button>
      </div>
      <div class="table_content_box">
        <div v-show="activeName === 'deposit'">
          <el-table :data="depositTable" style="width: 100%" border>
            <el-table-column prop="date" :label="$t('common.column.date')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
              <template slot-scope="accountScope">
                {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.method')" min-width="120">
              <template slot-scope="methodScope">
                <span v-if="methodScope.row.method === 2">
                  {{ showMethod2DepositMethod(methodScope.row.channel) }}
                </span>
                <span v-else-if="[77, 79, 80, 81, 82, 83, 84].includes(methodScope.row.method)">
                  {{ formateDepositMethod(methodScope.row.method, methodScope.row.channel, false) }}
                </span>
                <span v-else>{{ formateDepositMethod(methodScope.row.method, methodScope.row.channel) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.amt')" min-width="120">
              <template slot-scope="amountScope">
                {{ amountScope.row.currency | currencyToSymbol }}{{ amountScope.row.amount | formatNumber(2) }}
              </template>
            </el-table-column>
            <el-table-column min-width="120">
              <template slot="header" slot-scope="scope">
                <span>{{ $t('common.column.status') }}</span>
                <el-popover placement="bottom-end" trigger="hover" popper-class="trans_status_popover">
                  <div class="popover_inner">
                    <div class="status_item" v-for="item in statusData" :key="item.index">
                      <div class="item_left">
                        <div :class="['color_dot', `${item.color}_bg`]"></div>
                        <span class="status_name"> {{ item.name }}：</span>
                      </div>
                      <div class="status_desc" v-if="item.desc">{{ item.desc }}</div>
                      <i18n v-if="item.path" :path="item.path" tag="p" class="status_desc">
                        <template v-slot:mailTo>
                          <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                        </template>
                      </i18n>
                    </div>
                  </div>
                  <svg-icon class="status_popover_icon" slot="reference" iconClass="help"></svg-icon>
                </el-popover>
              </template>
              <template slot-scope="statusScope">
                {{ depositStatus[statusScope.row.status] | transactionStatus }}
              </template>
            </el-table-column>
          </el-table>
          <SPagination
            @current-change="handleDepositCurrentChange"
            @size-change="handleDepositSizeChange"
            :currentPage.sync="depositPageNo"
            :total="depositTotal"
          >
          </SPagination>
        </div>

        <div v-show="activeName === 'withdraw'">
          <el-table :data="withdrawTable" style="width: 100%" border>
            <el-table-column prop="date" :label="$t('common.column.date')" min-width="120"></el-table-column>
            <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
              <template slot-scope="accountScope">
                {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.method')" min-width="120">
              <template slot-scope="methodScope">
                <span v-if="methodScope.row.method === 2">
                  {{ showMethod2WithdrawMethod(methodScope.row.transferCountry) }}
                </span>
                <span v-else>{{ showWithdrawMethod(methodScope.row.method) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.amt')" width="232">
              <template slot-scope="amountScope">
                <p class="amount-text">
                  <span class="info-icon" @click="showDetails(amountScope, $event)"></span>
                  {{ amountScope.row.currency | currencyToSymbol }}
                  {{ amountScope.row.amount | formatNumber(2) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.status')" min-width="120">
              <template slot="header" slot-scope="scope">
                <span>{{ $t('common.column.status') }}</span>
                <el-popover placement="bottom-end" trigger="hover" popper-class="trans_status_popover">
                  <div class="popover_inner">
                    <div class="status_item" v-for="item in statusData" :key="item.index">
                      <div class="item_left">
                        <div :class="['color_dot', `${item.color}_bg`]"></div>
                        <span class="status_name"> {{ item.name }}：</span>
                      </div>
                      <div class="status_desc" v-if="item.desc">{{ item.desc }}</div>
                      <i18n v-if="item.path" :path="item.path" tag="p" class="status_desc">
                        <template v-slot:mailTo>
                          <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                        </template>
                      </i18n>
                    </div>
                  </div>
                  <svg-icon class="status_popover_icon" slot="reference" iconClass="help"></svg-icon>
                </el-popover>
              </template>
              <template slot-scope="statusScope">
                {{ depositStatus[statusScope.row.status] | transactionStatus }}
              </template>
              <template slot-scope="statusScope">
                {{
                  getWithdrawStatus(
                    statusScope.row.status,
                    statusScope.row.processedByOp,
                    statusScope.row.reversedType,
                  ) | transactionStatus
                }}
              </template>
            </el-table-column>
            <el-table-column min-width="120" v-if="showCancelWithdraw">
              <template slot-scope="statusScope">
                <el-button
                  v-if="showCancelButton(statusScope.row)"
                  @click="cancelWithdraw(statusScope.row)"
                  size="mini"
                  class="btn-blue cancel-btn"
                >
                  {{ $t('common.button.cancel') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <SPagination
            @current-change="handleWithdrawCurrentChange"
            @size-change="handleWithdrawSizeChange"
            :currentPage.sync="withdrawPageNo"
            :total="withdrawTotal"
          >
          </SPagination>
        </div>

        <TransferHistory v-show="activeName === 'transfer'" />
      </div>
    </div>
    <SDialog :visible.sync="dialogVisible" :title="$t('common.field.exportData')">
      <el-form label-position="top" :model="form" ref="form" status-icon class="dialog_inner_form">
        <AccountNumber supportedCurrencies="all" @setAccountNumber="setAccountNumber"></AccountNumber>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false" data-testid="cancel">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button type="primary" @click="onConfirm" data-testid="ok">
          {{ $t('common.button.ok') }}
        </el-button>
      </div>
    </SDialog>

    <!-- cancel withdraw dialog -->
    <SDialog :visible.sync="withdrawalCancelDialog">
      <div style="text-align: center;">{{ $t('transcationHistory.cancelWithdraw') }}</div>
      <div slot="footer">
        <el-button plain @click="closeDialog" data-testid="no">
          {{ $t('common.field.no') }}
        </el-button>
        <el-button type="primary" @click="submitCancelWithdraw" data-testid="yes">
          {{ $t('common.field.yes') }}
        </el-button>
      </div>
    </SDialog>

    <!-- amount detail dialog -->
    <SDialog :visible.sync="isShowDetailDialog" @close="closeDetailDialog" fullscreen :showFooter="false">
      <div class="amount-detail-content" v-cloak>
        <div class="title">{{ withdrawDialogLabel[withdrawDetail.withdrawalTypeCategory] }}</div>
        <div class="payment-icon-container">
          <img
            v-show="withdrawDetail.withdrawalType"
            class="payment-img"
            :class="[getWidthdrawalIconMap(withdrawDetail.withdrawalType)]"
            :src="
              withdrawDetail.withdrawalType
                ? require(`@/assets/images/withdraw/${getWidthdrawalIconMap(withdrawDetail.withdrawalType)}.png`)
                : ''
            "
            :alt="withdrawDetail.withdrawalType"
          />
          <div class="payment-title">{{ $t('transcationHistory.dialog.appliedAmount') }}</div>
          <div class="payment-amount">
            {{ withdrawDetail.accountCurrency | currencyToSymbol }}{{ withdrawDetail.appliedAmount }}
          </div>
        </div>
        <div class="payment-detail">
          <template v-for="(column, idx) in withdrawDialogOrders">
            <div
              :key="idx"
              class="item"
              v-if="withdrawDetail[column] || withdrawDetail[column] === 0 || withdrawDetail[column] === '0'"
            >
              <div class="payment-title">{{ $t(`transcationHistory.dialog.${column}`) }}</div>
              <div class="payment-text">
                {{ getWithdrawDialogContent(column) }}
              </div>
            </div>
          </template>

          <el-button v-if="withdrawDetail.bankStatement" class="btn-blue bank-statement-btn" @click="showBankStatement">
            {{ $t('transcationHistory.dialog.bankStatement') }}
          </el-button>
        </div>
      </div>
    </SDialog>
  </PageWrapper>
</template>

<script>
import {
  apiTransactionHistory,
  apiDownloadAccountTransactionHistoryPDF,
  apiCancelWithdraw,
  apiTransactionHistoryDetail,
} from '@/resource'
import TransferHistory from '@/components/transactions/TransferHistory'
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue'
import AccountNumber from '@/components/form/AccountNumber'
import moment from 'moment'
import {
  depositMethodsDB,
  depositStatusDB,
  withdrawStatusDB,
  withdrawReversedTypeDB,
  withdrawDialogOrdersDB,
} from './dataBase'

export default {
  name: 'TransactionHistory',
  components: { TransferHistory, InteractiveDialog, AccountNumber },
  filters: {
    formateDialogDate: value => {
      if (value) {
        return moment(value).format('DD-MM-YYYY, HH:mm:ss')
      }
      return ''
    },
  },
  data() {
    const defaultRange = 7
    const exportEndDate = new Date()
    const exportStartDate = new Date(
      exportEndDate.getFullYear(),
      exportEndDate.getMonth(),
      exportEndDate.getDate() - defaultRange,
    )
    return {
      pageTabs: [
        { name: 'deposit', dataTestId: 'deposit', label: this.$t('transcationHistory.deposit') },
        { name: 'withdraw', dataTestId: 'withdraw', label: this.$t('transcationHistory.withdraw') },
        { name: 'transfer', dataTestId: 'transfer', label: this.$t('menu.tranHis') },
      ],

      startDate: exportStartDate,
      endDate: exportEndDate,
      form: {
        accountNumber: null,
      },
      dialogVisible: false,
      dateOption: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      dateFormat: 'YYYY-MM-DD',
      depositPageNo: 1,
      depositPageSize: 25,
      depositUrl: 'deposit',
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      withdrawUrl: 'withdraw',
      depositTotal: 0,
      withdrawTotal: 0,
      depositTable: [],
      depositMethods: depositMethodsDB,
      depositStatus: depositStatusDB,
      withdrawTable: [],
      withdrawMethods: this.$config.withdrawMethods(this.$store.state.common.regulator),
      withdrawStatus: withdrawStatusDB,
      withdrawReversedType: withdrawReversedTypeDB,
      activeName: 'deposit',
      withdrawalCancelDialog: false,
      cancelInfo: null,
      ngaCountryCode: 5796,
      isShowDetailDialog: false,
      withdrawDetail: {},
      withdrawDialogOrders: withdrawDialogOrdersDB,
      withdrawDialogLabel: {
        1: this.$t('common.withdrawChannel.banktransfer'),
        2: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
        3: this.$t('common.withdrawChannel.creditcard'),
        4: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
        5: this.$t('common.withdrawChannel.E_WALLET'),
      },
    }
  },
  computed: {
    showCancelWithdraw() {
      if (this.withdrawTable)
        return this.withdrawTable.find(
          // 20220811 UninoPay not support CancelWithdraw (method: 4)
          element =>
            !element.processedByOp &&
            (element.status === 1 || element.status === 3 || element.status === 5) &&
            element.method !== 4,
        )
    },
    regulator() {
      return this.$store.state.common.regulator
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    statusData() {
      if (this.activeName === 'deposit') {
        return [
          {
            color: 'incomplete',
            name: this.$t('common.field.incomplete'),
            desc: this.$t('transcationHistory.desc.incomplete'),
          },
          {
            color: 'success',
            name: this.$t('common.field.successful'),
            desc: this.$t('transcationHistory.desc.success'),
          },
          { color: 'pending', name: this.$t('common.field.processing'), path: 'transcationHistory.desc.proc' },
          { color: 'fail', name: this.$t('common.field.failed'), path: 'transcationHistory.desc.failed' },
          { color: 'rejected', name: this.$t('common.field.rejected'), path: 'transcationHistory.desc.rejected' },
        ]
      }
      return [
        {
          color: 'pending',
          name: this.$t('common.field.submitted'),
          desc: this.$t('transcationHistory.withdrawDesc.sub'),
        },
        {
          color: 'success',
          name: this.$t('common.field.successful'),
          desc: this.$t('transcationHistory.withdrawDesc.suc'),
        },
        { color: 'pending', name: this.$t('common.field.processing'), path: 'transcationHistory.withdrawDesc.proc' },
        {
          color: 'incomplete',
          name: this.$t('common.field.incomplete'),
          path: 'transcationHistory.withdrawDesc.incomp',
        },
        {
          color: 'incomplete',
          name: this.$t('common.field.cancelled'),
          path: 'transcationHistory.withdrawDesc.cancel',
        },
        { color: 'fail', name: this.$t('common.field.failed'), path: 'transcationHistory.withdrawDesc.fail' },
        { color: 'rejected', name: this.$t('common.field.rejected'), path: 'transcationHistory.withdrawDesc.rej' },
      ]
    },
  },
  mounted() {
    //init transaction history
    this.initTransactionHistory(1)
    this.initTransactionHistory(2)
  },
  methods: {
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo
      this.initTransactionHistory(2)
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size
      this.initTransactionHistory(2)
    },
    handleDepositCurrentChange(pageNo) {
      this.depositPageNo = pageNo
      this.initTransactionHistory(1)
    },
    handleDepositSizeChange(size) {
      this.depositPageSize = size
      this.initTransactionHistory(1)
    },
    queryTransactionHistory(url, pageNo, pageSize) {
      return apiTransactionHistory(url, {
        pageNo: pageNo,
        pageSize: pageSize,
      })
    },
    initTransactionHistory(type) {
      switch (type) {
        case 1:
          this.queryTransactionHistory(this.depositUrl, this.depositPageNo, this.depositPageSize).then(resp => {
            if (resp.data.code === 0) {
              this.depositTotal = resp.data.data.total
              this.depositTable = resp.data.data.depositHistory
            }
          })
          break
        case 2:
          this.queryTransactionHistory(this.withdrawUrl, this.withdrawPageNo, this.withdrawPageSize).then(resp => {
            if (resp.data.code === 0) {
              this.withdrawTotal = resp.data.data.total
              this.withdrawTable = resp.data.data.withdrawHistory
            }
          })
          break
        default:
          break
      }
    },
    showMethod2DepositMethod(channel) {
      if (this.countryCode == this.ngaCountryCode && channel === 1 && this.regulator === 'svg') {
        return this.formateDepositMethod(2, 3)
      }
      return this.formateDepositMethod(2, channel)
    },

    // hasLang 表示是否有国际化翻译， 这个参数是为了区别 新旧  method和channel， 新加的没有国际化翻译
    formateDepositMethod(method, channel, hasLang = true) {
      const methodValue = this.depositMethods[method]
      if (methodValue) {
        if (typeof methodValue === 'string') {
          if (hasLang) {
            return this.$options.filters.depositMethods(methodValue)
          }
          return methodValue
        } else {
          if (methodValue[channel]) {
            if (hasLang) {
              return this.$options.filters.depositMethods(methodValue[channel])
            }
            return methodValue[channel]
          }
          return channel
        }
      } else {
        return method
      }
    },
    showWithdrawMethod(method) {
      return this.withdrawMethods[method] ? this.$options.filters.withdrawMethods(this.withdrawMethods[method]) : method
    },
    showMethod2WithdrawMethod(transferCountry) {
      if (this.countryCode == this.ngaCountryCode && transferCountry === 2 && this.regulator === 'svg')
        return this.$options.filters.withdrawMethods(this.withdrawMethods[2][13])
      return this.withdrawMethods[2][transferCountry]
        ? this.$options.filters.withdrawMethods(this.withdrawMethods[2][transferCountry])
        : transferCountry
    },
    onExportClick() {
      this.dialogVisible = true
    },
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber
    },
    changeStartDate(val) {
      this.startDate = val
    },
    changeEndDate(val) {
      this.endDate = val
    },
    onConfirm() {
      const reqParam = {
        startDate: moment(this.startDate).format(this.dateFormat),
        endDate: moment(this.endDate).format(this.dateFormat),
        accountNumber: this.form.accountNumber.toString(),
      }
      apiDownloadAccountTransactionHistoryPDF(reqParam)
        .then(resp => {
          if (resp.data.code == 0) {
            this.pdfHandler(resp.data.data, reqParam)
          } else {
            this.errorMessage(this.$t('responseMsg.500'))
            this.dialogVisible = false
          }
        })
        .catch(err => {
          this.errorMessage(this.$t('responseMsg.500'))
          this.dialogVisible = false
        })
    },
    errorMessage(message) {
      this.$message({
        message: message,
        type: 'error',
      })
    },
    pdfHandler(data, reqParam) {
      const { startDate, endDate, accountNumber } = reqParam
      const filename = `${accountNumber} ${startDate} to ${endDate}.pdf`
      const bufferArray = this.base64ToArrayBuffer(data)
      const blob = new Blob([bufferArray], { type: 'application/pdf' })
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const linkId = 'export_file'
      link.setAttribute('id', linkId)
      link.setAttribute('href', objectUrl)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.getElementById(linkId).remove()
      this.dialogVisible = false
    },
    base64ToArrayBuffer(data) {
      const bString = window.atob(data)
      const bLength = bString.length
      let bytes = new Uint8Array(bLength)
      for (let i = 0; i < bLength; i++) {
        bytes[i] = bString.charCodeAt(i)
      }
      return bytes
    },
    showCancelButton(item) {
      // 20220811 UninoPay not support CancelWithdraw (method: 4)
      const { status, processedByOp, method } = item
      return !processedByOp && (status === 1 || status === 3 || status === 5) && method !== 4
    },
    cancelWithdraw(info) {
      this.withdrawalCancelDialog = true
      this.cancelInfo = info
    },
    closeDialog() {
      this.withdrawalCancelDialog = false
      this.cancelInfo = null
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog()
            this.initTransactionHistory(2)
          } else {
            this.showErrorMsg()
          }
        })
        .catch(() => {
          this.showErrorMsg()
        })
    },
    showErrorMsg() {
      this.$message({
        message: this.$t('transcationHistory.cancelError', {
          supportEmail: this.GLOBAL_CONTACT_EMAIL,
        }),
        type: 'error',
        duration: 10000,
      })

      this.closeDialog()
      this.initTransactionHistory(2)
    },
    getWithdrawStatus(status, processedByOp, reversedType) {
      if (reversedType) return this.withdrawReversedType[reversedType]
      return !processedByOp && (status === 3 || status === 5) ? this.withdrawStatus[1] : this.withdrawStatus[status]
    },
    async showDetails(item, event) {
      try {
        const { data } = await apiTransactionHistoryDetail(item.row.id)
        if (data.code === 0) {
          this.withdrawDetail = { ...data.data }
          this.isShowDetailDialog = true
          event.target.classList.add('clicked')
        }
      } catch (error) {
        this.errorMessage(error)
      }
    },
    showBankStatement() {
      if (this.withdrawDetail.bankStatement) {
        window.open(`${location.origin}${this.withdrawDetail.bankStatement}`, '_blank')
      }
    },
    getWidthdrawalIconMap(value) {
      switch (value) {
        case 1:
          return `visa_icon`
        case 2:
          return `swift_icon`
        case 34:
          return `bitcoin_icon`
        case 36:
          return `usdt_icon`
        case 4:
        case 5:
        case 6:
        case 8:
        case 9:
        case 24:
        case 35:
        case 39:
        case 40:
        case 42:
        case 47:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
        case 56:
        case 57:
        case 58:
        case 60:
        case 62:
        case 63:
        case 64:
        case 65:
        case 66:
        case 67:
        case 68:
          return `vietna_icon`
        case 7:
          return `fasapay_icon`
        case 31:
          return `skrill_icon`
        case 32:
          return `neteller_icon`
        case 37:
          return `bitwallet_icon`
        case 38:
          return `sticpay_icon`
        case 41:
          return `astropay_icon`
        case 61:
          return `perfect_money_icon`
        default:
          return
      }
    },
    closeDetailDialog() {
      this.isShowDetailDialog = false
      this.withdrawDetail = {}
    },
    getWithdrawDialogContent(column) {
      switch (column) {
        case 'applicationTime':
          return this.$options.filters.formateDialogDate(this.withdrawDetail.applicationTime)
        case 'processedTime':
          return this.$options.filters.formateDialogDate(this.withdrawDetail.processedTime)
        case 'accountCurrency':
          return this.$options.filters.currencyToSymbol(this.withdrawDetail.accountCurrency)
        case 'creditDeducted':
          return `${this.$options.filters.currencyToSymbol(this.withdrawDetail.currencyToSymbol)}${
            this.withdrawDetail.creditDeducted
          }`
        default:
          return this.withdrawDetail[column]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/transactionHistory.scss';
#transactionHistory {
  background-color: $white;
  min-height: 650px;
  padding: 24px;
  border-radius: 8px;
  .export_form_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include screen-mobile {
      flex-direction: column;
      justify-content: center;
    }
    & /deep/ .el-form {
      width: 100%;
      display: flex;
      align-items: flex-end;
      @include screen-mobile {
        flex-direction: column;
      }
      .el-form-item {
        width: 240px;
        margin-bottom: 16px;
        @include rtl-sass-prop(margin-right, margin-left, 16px);
        .el-date-editor {
          width: 100%;
        }
        @include screen-mobile {
          width: 100%;
          @include rtl-sass-prop(margin-right, margin-left, 0);
        }
      }
    }
    .btn_form_item {
      height: auto;
      margin-bottom: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.dialog_inner_form {
  .el-select {
    width: 100%;
  }
}
.trans_status_popover {
  &.el-popover {
    width: 463px;
    box-sizing: border-box;
    @include screen-mobile {
      max-width: 360px;
      width: 360px;
    }
    .popover_inner {
      .status_item {
        margin-bottom: 16px;
        .item_left {
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          .color_dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            @include rtl-sass-prop(margin-right, margin-left, 10px);
          }
          .status_name {
            color: $text-primary;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600px;
          }
        }
        .status_desc {
          color: $text-placeholder;
          font-size: 12px;
          line-height: 18px;
          @include rtl-sass-prop(padding-left, padding-right, 18px);
          a {
            color: $text-link;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.success_bg {
  background-color: #30bf58;
}
.fail_bg {
  background-color: #ffde32;
}
.rejected_bg {
  background-color: #cd2a2a;
}
.pending_bg {
  background-color: #f39924;
}
.incomplete_bg {
  background-color: #acacbf;
}
.status_popover_icon {
  width: 16px;
  color: $primary;
  @include rtl-sass-prop(margin-left, margin-right, 10px);
}

.amount-detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 36px;
}

.payment-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.payment-img {
  height: 84px;
  width: auto;
  max-width: 180px;
  margin-bottom: 8px;

  &.visa_icon,
  &.fasapay_icon,
  &.skrill_icon,
  &.neteller_icon,
  &.sticpay_icon,
  &.astropay_icon {
    max-height: 40px;
  }
}
.payment-title {
  font-size: 16px;
  font-weight: 400;
  color: $pu-dark-gray;
}
.payment-amount {
  font-size: 30px;
  font-weight: 800;
  color: $blue;
  line-height: 40px;
}

.payment-detail {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: space-between;
    .payment-title {
      text-align: left;
    }
    .payment-text {
      font-size: 16px;
      font-weight: 900;
      text-align: right;
      max-width: 300px;
      word-break: break-word;
    }
  }

  .item + .item {
    margin-top: 10px;
  }
}

.bank-statement-btn {
  margin-top: 40px;
}

@media (max-width: 540px) {
  .payment-detail {
    .item {
      flex-direction: column;
      align-items: center;
      .payment-text {
        text-align: left;
        margin-top: 8px;
      }
    }
  }
}
</style>
