<template>
  <SPagination :current-page="currentPage" :page-size="pageSize" :total="total" @change="onPageChange"></SPagination>
</template>

<script>
import SPagination from './index.vue'
export default {
  components: {
    SPagination,
  },
  props: {
    rawData: {
      type: Array,
      default: () => {
        return []
      },
    },
    displayData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
    }
  },
  computed: {
    total() {
      return this.rawData.length
    },
  },
  watch: {
    rawData: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.updateData()
        }
      }
    }
  },
  methods: {
    updateData() {
      const data = this.rawData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      this.$emit('update:display-data', data)
    },
    resetPage() {
      this.currentPage = 1
      this.updateData()
    },
    onPageChange(page, size) {
      this.currentPage = page
      this.pageSize = size
      this.updateData()
    },
  },
}
</script>
