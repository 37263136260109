import { render, staticRenderFns } from "./TransferHistory.vue?vue&type=template&id=8280814e&scoped=true&"
import script from "./TransferHistory.vue?vue&type=script&lang=js&"
export * from "./TransferHistory.vue?vue&type=script&lang=js&"
import style0 from "./TransferHistory.vue?vue&type=style&index=0&id=8280814e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8280814e",
  null
  
)

export default component.exports