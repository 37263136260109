export const depositMethodsDB = {
  1: 'Credit/Debit Card', // 信用卡
  2: {
    1: 'Bank Wire Transfer (International)', // 银行汇款（国际）
    2: 'Bank Wire Transfer (Australia)', // 银行汇款（澳大利亚）
    3: 'Skrill / Moneybookers', // skrill
    4: 'Neteller', // neteller
    5: 'Bpay', // Bpay
    6: 'POLI Payment', // Poli Payment
    7: 'Broker to Broker Transfer', // Broker To Broker Transfer
    8: 'Wechat Pay', // Wechat Pay  微信支付
    13: 'Nigeria Bank Transfer', // Nigeria bank transfer
    175: 'Bank Transfer (International)', // 银行汇款（Equals）
    188: 'Bank Transfer (International)', // 银行汇款（Equals）
  }, // 线下支付 "Offline Payment"
  3: 'Union Pay', // 银联
  4: 'Internet Banking (Thailand)', //泰国入金方式，针对泰国用户使用
  5: 'Internet Banking (Malaysia)', // 马来西亚入金方式，针对泰国用户使用
  6: 'Quick Payment', // 快捷支付
  7: 'FasaPay', // FasaPay
  8: 'Internet Banking (Vietnam)', //越南专用zotaPay
  9: 'Mobile Pay', //移动支付
  10: 'AppPay', // AppPay
  11: 'Neteller', // Neteller
  12: 'Internet Banking (Nigeria)', // Nigeria
  13: 'UnionPay Transfer', // UnionPay2
  14: 'Paypal', // Paypal
  15: {
    1: 'cryptobitcoin',
    2: 'cryptotetheromni',
    3: 'cryptotethererc20',
    4: 'cryptotethererc20',
    5: 'cryptotetheromni',
    6: 'cryptobitcoin',
    7: 'cryptotethertrc20',
    8: 'cryptocurrencyeth',
    9: 'cryptocurrencyusdc',
    120: 'cryptobitcoinapp',
    131: 'cryptotetheromniapp',
    143: 'cryptobitcoincpsapp',
    144: 'cryptotetheromnicpsapp',
    145: 'cryptobitcoinerc20cpsapp',
    146: 'cryptobitcointrc20cpsapp',
  },
  16: 'Canadian Local Bank Transfer',
  17: 'SticPay',
  18: 'Internet Banking (Indonesia)', //印尼入金
  19: {
    2: 'South Korea Bank Transfer',
    3: 'Internet Banking (South Korea)',
  },
  20: 'Bitwallet',
  21: {
    1: 'Skrill / Moneybookers',
    2: 'Neteller',
  },
  22: 'trustly',
  23: 'polipayment',
  24: {
    1: 'Internet Banking (India)',
    2: 'India Bank Transfer',
    3: 'Internet Banking (India)',
    7: 'Internet Banking (India)',
    9: 'Internet Banking (India)',
  },
  25: 'Internet Banking (Philippine)',
  27: 'astropay',
  29: 'Internet Banking (South Africa)',
  32: 'Vload',
  35: 'UPI',
  40: 'Internet Banking (Uganda)',
  41: 'Internet Banking (Rwanda)',
  42: 'Internet Banking (Zambia)',
  43: 'Internet Banking (Congo)',
  44: 'Internet Banking (Cameroon)',
  45: 'Internet Banking (Burundi)',
  46: 'Internet Banking (Kenya)',
  47: 'Internet Banking (Ghana)',
  48: 'Internet Banking (Tanzania)',
  49: 'Perfect Money',
  54: 'iDeal',
  55: 'Local Depositor',
  72: 'GoPay',
  73: 'Momopay',
  77: {
    1: 'Wire transfer',
    2: 'VIP通道',
    3: 'NganLuong ATM Card',
    4: 'NganLuong Internet Banking',
    5: 'NganLuong Qrcode',
    6: 'NganLuong Ewallet',
    7: 'ThunderX Pay THB',
    8: 'EPay USD',
    9: 'AccentPay MYR',
    10: 'DragonPhoenix PHP',
    11: 'M-Pesa KES',
    12: 'AccentPay Credit EUR',
    13: 'digital money',
    14: '电汇转账SEPA',
    15: 'moonpay USDT',
    16: 'Tinkbit USD',
    17: 'FasaPay IDR',
    18: 'OneTopay USD',
    19: 'OneTopay JPY',
    20: 'OneTopay EUR',
    21: 'Ipay KES',
    22: '电汇转账 NGN',
    23: 'NganLuong Bank Transfer',
    24: 'CPS USDT',
    25: 'iPayTotal',
    26: 'RealDeposit',
    27: 'MerchantPay',
    28: 'CPS H5 THB',
    29: 'SEPA transfer EUR',
    30: 'IP Plus',
    31: 'Local Bank Account',
    32: 'RealDeposit ZAR',
    33: 'CPS USDT-OMNI',
    34: 'CPS BTC',
    35: 'CPS H5 VND',
    36: 'CPS PSP VND',
    37: 'CPS Online Bank VND',
    38: 'ThunderX Pay MMK',
    39: 'ThunderX Pay KHR',
    40: 'HelpToPay PHP',
    41: 'Croin Plus',
    42: 'CPS USDT-TRC20',
    43: 'Payment Asia Paytrust IDR',
    44: 'Payment Asia Paytrust THB',
    45: 'Payment Asia Paytrust VND',
    46: 'BobKehek',
    49: 'ESeriesXch',
    50: 'GFX',
    51: 'Aijapan',
    52: 'Croin plus IDR',
    53: 'Croin plus MYR',
    54: 'VaultsPay',
    55: 'UDAYSARI BROKARAGE',
    56: 'NIPPLUS THB',
    57: 'Eeziepay IDR',
    58: 'Eeziepay MYR',
    59: 'Eeziepay THB',
    60: 'NRG FX CAPITAL USD',
    61: 'NRG FX CAPITAL CAD',
    62: 'FastPay VND',
    63: 'XPAY P2P THB',
    64: 'XPAY QRCode THB',
    65: 'XPAY P2C MYR',
    66: 'XPAY P2P IDR',
    67: 'XPAY P2P VND',
    68: 'Spayzio T-KRW',
    69: 'Spayzio U-KRW',
    70: 'Skrill VISA/MASTER EUR',
    71: 'Astropay EUR',
    72: 'Astropay USD',
    73: 'Direct Pay 2D EUR',
    74: 'Direct Pay 2D USD',
    75: 'MPay CNY',
    76: 'SorinXE USD',
    77: 'Nasim Al Barari USD',
    78: 'MPay THB',
    79: 'Citizen EUR',
    80: 'Citizen USD',
    81: 'StarPay IDR',
    82: 'T365 BT THB',
    83: 'T365 BT MYR',
    84: 'T365 BT IDR',
    85: 'T365 ATM IDR',
    86: 'T365 BT VND',
    87: 'T365 MOMO VND',
    88: 'UPay KRW',
    89: 'India Cash X Vgroup USD',
    90: 'Wahrheit-India Offline USD',
    91: 'ST Pakistan USD',
    92: 'Croin plus NZD',
    93: 'Globepay USD',
    94: 'Pagsmile USD',
    95: 'Pagsmile COP',
    96: 'Pagsmile CLP',
    97: 'Pagsmile MXN',
    98: 'Pagsmile PEN',
    99: 'Egypt LD USD',
    100: 'Myfatoorah KWD',
    101: 'Myfatoorah SAR',
    102: 'Myfatoorah BHD',
    103: 'Myfatoorah AED',
    104: 'Myfatoorah OMR',
    105: 'Myfatoorah JOD',
    106: 'Myfatoorah EGP',
    107: 'Wire transfer AED',
    108: 'Wire transfer EUR',
    109: 'Wise Email Transfer EUR',
    110: 'AirTM USD',
    111: 'Banxa CAD',
    112: 'Mobile payment',
    113: 'NganLuong ATM Offline',
  },
  79: {
    131: '12PAY -CPS',
    138: 'ThunderX Pay-CPS',
  },
  80: {
    132: 'Croin plus-CPS',
    133: 'Forex Currency Deal-CPS',
  },
  81: {
    134: 'Payment Asia Paytrust-CPS',
    136: 'Payment Asia VNPay-CPS',
    137: 'Payment Asia Dragon Pay-CPS',
    142: 'PaymentAsia AsiaBank-CPS',
  },
  82: {
    135: 'Deposit RMB-CPS',
  },
  83: {
    141: 'BetcatPay-CPS',
  },
  84: {
    140: 'Konnectivity-CPS',
    130: 'Online Naira-CPS',
  },
}

export const depositStatusDB = {
  1: 'Incomplete',
  2: 'Incomplete',
  3: 'Failed',
  4: 'Processing',
  5: 'Successful',
  6: 'Processing',
  7: 'Rejected',
  8: 'Processing',
  9: 'Processing',
}
export const withdrawStatusDB = {
  // WITHDRAW_SUBMIT(1), // 提交中
  // WITHDRAW_CANCEL(2), // 取消
  // WITHDRAW_AUDIT(3), // 审核中
  // WITHDRAW_REFUSE(4), // 拒绝
  // WITHDRAW_ACCEPT(5), // 受理
  // WITHDRAW_PAYFAIL(6), // 打款失败
  // WITHDRAW_SUCCESS(7), // 出金成功
  // WITHDRAW_FAIL(8), // 出金失败
  // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
  // WITHDRAW_HALFFAILED(10); // 部分失败
  1: 'Submitted',
  2: 'Cancelled',
  3: 'Processing',
  4: 'Rejected',
  5: 'Processing',
  6: 'Failed',
  7: 'Successful',
  8: 'Failed',
  9: 'Processing',
  10: 'Failed',
}
export const withdrawReversedTypeDB = {
  // 1 Cancelled by client at accept
  // 2 Reversed by op at accept
  // 3 Reversed by op at complete
  1: 'Cancelled',
  2: 'Failed',
  3: 'Failed',
}
export const withdrawDialogOrdersDB = [
  // control withdraw dialog column orders (data refer to withdrawDetail object)
  'applicationTime',
  'processedTime',
  'accountCurrency',
  'creditDeducted',
  'processedNote',
  'bankName',
  'bankAccountNumber',
  'abaSortCode',
  'swiftCode',
  'bankAddress',
  'accountName',
  'accountNumber',
  'cardholderName',
  'cardNumber',
  'cardExpiryDate',
  'walletAddress',
  'ewalletEmailAddress',
]
