<template>
  <div class="page-box">
    <el-pagination
      :background="true"
      :page-size="pageSize"
      layout="total, sizes, pager, prev , next"
      :total="total"
      :current-page="currentPage"
      :page-sizes="[10, 25, 50, 100]"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      data-testid="pagination"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      displayData: this.tableData.slice(0, 10),
      total: this.tableData.length,
    }
  },
  watch: {
    tableData(val) {
      this.updateData()
      this.total = Array.isArray(val) ? val.length : 0
    },
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.updateData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.updateData()
    },
    updateData() {
      this.displayData = this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      this.$emit('update:display-data', this.displayData)
    },
    resetPage() {
      this.currentPage = 1
      this.updateData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/pagination.scss';
</style>
